/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */
'use strict';
__webpack_public_path__ = window.$__webpack_public_path__;

var page = require('@foundation/js/page'),
    util = require('@foundation/js/util'),
    AsyncRunner = require('@foundation/js/pages/async-runner'),
    navigation = require('./navigation'),
    navlevel2 = require('./navlevel2'),
    countryselector = require('./countryselector'),
    headerbanner = require('./headerbanner'),
    discountbanner = require('./discountbanner'),
    _ = require('lodash');

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('@foundation/js/jquery-ext')();
require('@foundation/js/cookieprivacy')();
require('@foundation/js/horizontal-carousel')();
require('@foundation/js/SwiperProxy');

function initializeEvents() {
    //footer toggle
    if ($(window).width() < 960) {
        var lastfooteritem = '';
        (function ($) {

            var allPanels = $('.footer-item ul').hide().attr('aria-hidden', true);
            $('.footer-item h3').attr('role', 'button').attr('aria-expanded', false);

            $('.footer-item h3').on('click', function () {
                allPanels.slideUp().attr('aria-hidden', true);
                $('.footer-item h3').removeClass('open-footer').attr('aria-expanded', false);
                if ($(this).text() != lastfooteritem) {
                    $(this).next().slideDown().attr('aria-hidden', false);
                    $(this).addClass('open-footer').attr('aria-expanded', true);
                    lastfooteritem = $(this).text();
                } else {
                    lastfooteritem = '';
                    $(this).removeClass('open-footer');
                }
                return false;
            });
        })(jQuery);
    }
}

/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();
    util.phoneValidation();
}
var pages = {
    storefront: '@foundation/js/pages/storefront',
};

var app = {
    init: function () {
        window.AsyncRunner = AsyncRunner.getInstance(); // INTENTIONALLY setting global

        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html('<span class="alert-icon"></span>' + Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }
        $.extend(page, window.pageContext);
        var ns = page.ns;
        initializeDom();
        initializeEvents();
        // init specific global components
        navigation.init();
        navlevel2.init();
        countryselector.init();
        headerbanner.init();
        discountbanner.init();
        // execute page specific initializations
        if (ns && pages[ns]) {
            switch (ns) {
                case 'storefront':
                    require.ensure([], function (require) {
                        var chunk = require('@foundation/js/pages/storefront');
                        if (chunk.init) {
                            chunk.init();
                        }
                    }, function (error) {
                        console.log('error!', error);
                    }, 'storefront');
                    break;
            }
        }
    }
};
$(window).on('resize', _.debounce(
    function () {
        var position = $('.ui-dialog-content:not(.qs-dialog-content)').dialog('option', 'position');
        $('.ui-dialog-content:not(.qs-dialog-content)').dialog('option', 'position', position);
    },
    200)
);
// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();
// initialize app
$(document).ready(function () {
    console.log('app initialized');
    app.init();
});

// footer country selector slideup/down

var lastfooteritem = '';
(function ($) {

    $('.footer-container__right .country-selector .selector').hide().attr('aria-hidden', true);
    $('.footer-container__right').find('.country-selector span').attr('role', 'button').attr('aria-expanded', false);

    $('.footer-container__right').find('.country-selector span').on('click', function () {
        $('.footer-container__right .country-selector .selector').slideUp();
        $('.footer-container__right').find('.country-selector span').removeClass('open-footer').attr('aria-expanded', false);
        if ($(this).text() != lastfooteritem) {
            $('.footer-container__right .country-selector .selector').slideDown();
            $(this).addClass('open-footer').attr('aria-expanded', true);
            lastfooteritem = $(this).text();
        } else {
            lastfooteritem = '';
            $(this).removeClass('open-footer');
        }
        return false;
    });
})(jQuery);
